import '../stylesheets/index_page.scss';

let tag = document.querySelector('#bg-image-tag > img');
if (tag.complete) {
    console.log('complete')
    tag.style.opacity = '1';
} else {
    tag.style.opacity = '0';
    tag.addEventListener('load', function () {
        console.log('loaded');
        tag.style.transition = 'opacity 2s';
        tag.style.opacity = '1';
    });
}